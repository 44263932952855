import confetti from 'canvas-confetti';
import React, { useEffect } from 'react';
import Banner from 'sats-ui-lib/react/banner';
import LinkButton from 'sats-ui-lib/react/link-button';
import Text from 'sats-ui-lib/react/text';

import Logo from '../logo/logo';
import type { FlowTerminusLayout as Props } from './flow-terminus-layout.types';
import ContentContainer from '../content-container/content-container';

const FlowTerminusLayout: React.FC<React.PropsWithChildren<Props>> = ({
  actions,
  children,
  logo,
  messages,
  mood,
  title,
}) => {
  useEffect(() => {
    switch (mood) {
      case 'celebratory': {
        const count = 400;
        [
          {
            ratio: 0.25,
            spread: 26,
            startVelocity: 55,
          },
          { ratio: 0.2, spread: 60 },
          { ratio: 0.35, spread: 100, decay: 0.91, scalar: 0.8 },
          {
            ratio: 0.1,
            spread: 120,
            startVelocity: 25,
            decay: 0.92,
            scalar: 1.2,
          },
          { ratio: 0.1, spread: 120, startVelocity: 45 },
        ].forEach(({ ratio, spread, startVelocity }) =>
          confetti({
            disableForReducedMotion: true,
            origin: { y: 0.7 },
            particleCount: Math.floor(count * ratio),
            spread,
            startVelocity,
          })
        );
        break;
      }
    }
  }, []);

  return (
    <div className="flow-terminus-layout">
      <ContentContainer>
        <div className="flow-terminus-layout__content">
          <header className="flow-terminus-layout__logo">
            <Logo {...logo} size="large" />
          </header>
          <main className="flow-terminus-layout__card">
            <div className="flow-terminus-layout__title">
              <Text
                elementName="h1"
                size={Text.sizes.headline2}
                theme={Text.themes.headline}
                italic
              >
                {title}
              </Text>
            </div>
            <div className="flow-terminus-layout__messages">
              {messages.primary.map(entry => (
                <div key={entry.text}>
                  <Banner {...entry} />
                </div>
              ))}
            </div>
            <div className="flow-terminus-layout__children">{children}</div>
            <div className="flow-terminus-layout__messages">
              {messages.secondary.map(entry => (
                <div key={entry.text}>
                  <Banner {...entry} />
                </div>
              ))}
            </div>
            <div className="flow-terminus-layout__actions">
              {actions.map((entry, index) => (
                <div key={entry.href}>
                  <LinkButton
                    {...entry}
                    variant={index ? LinkButton.variants.secondary : undefined}
                  />
                </div>
              ))}
            </div>
          </main>
        </div>
      </ContentContainer>
    </div>
  );
};

export default FlowTerminusLayout;
